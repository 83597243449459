@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  padding: 4px 16px;
}

#mapid {
  height: 80vh;
  overflow: hidden;
  margin-bottom: 8px;
  cursor: auto;
}

.list {
  margin-top: 8px;
  height: 15vh;
  overflow-y: auto;
}

.bird {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border: 1px solid grey;
}

.bird-vertical {
  flex-direction: column;
}

.bird-vertical > div {
  width: 100% !important;
}

.bird > div {
  padding-right: 4px;
}

.bird-vertical .bird-count-class {
  height: 100px;
}

.bird-vertical input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  width: 100px;
  height: 100px;
}
